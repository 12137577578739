<template>
  <div class="room-lobby-page" v-if="showRoomLobby" :class="{oneCol: !(!isTabletOrDown && ifJoining && ifJitsiRoom)}">
    <div class="page-header">
      <img src="@/assets/global-icons/spacein_logo_white.svg" alt="" @click="handleLogoClick">
    </div>
    <div class="room-lobby" v-if="!loadingInviteData && !isEnteringAfterAuth && !isRejoiningAfterAuth">
      <div class="room-lobby__left" :class="{joining: ifJoining&&ifJitsiRoom}" v-if="!isTabletOrDown && ifJoining && ifJitsiRoom">
        <div class="conference-test">
          <div class="info">
            <p>Allow SPACEIN to use your camera and microphone.</p>
            <p class="text-white">Preview your audio and video.</p>
          </div>
          <jitsi-preview />
        </div>
      </div>

      <div class="room-lobby__right">
        <div v-if="!validInvite" class="invalid">
          {{invalidMessage}}
        </div>
        <div v-else-if="isRejected" class="invalid">
          You are removed from the lobby
        </div>
        <div v-else-if="isKicked" class="invalid">
          You are removed from the room
        </div>
        <div v-else-if="ifJoining" class="join-room">
          <div class="header">
            <p>{{`${isWaiting ? 'Joining Room...' : 'Join Room'}`}}</p>
            <h3 class="room-name">{{roomName}}</h3>
          </div>

          <div v-if="isWaiting" class="waiting">
            <LoadingIcon maxHeight="15vh" />
            <p>Please hold on, the host <br v-if="!isPasswordRequired"> will let you in soon<span v-if="isPasswordRequired">,
              <br>or enter the password to join the Room now.</span>
            </p>
            <inputfield
              v-if="isPasswordRequired"
              large
              rounded
              placeholder="Type in Room's password"
              :customClasses="['bg-light-black', 'text-white', 'text-bold']"
              v-model="enteredPassword"
              @keypress.enter="joinRoom"
              :type="showPassword ? 'text' : 'password'"
            >
              <template #image>
                <img
                  class="text-white"
                  src="@/assets/global-icons/eye-show.svg"
                  alt="show password"
                  v-if="showPassword && isWaiting"
                  @click="toggleShowPassword"
                />
                <img
                  class="text-white"
                  src="@/assets/global-icons/eye-hidden.svg"
                  alt="show password"
                  v-else-if="isWaiting"
                  @click="toggleShowPassword"
                />
              </template>
            </inputfield>
            <div class="buttons">
              <btn v-if="isPasswordRequired" :disabled="!enteredPassword" @click="joinRoom" rounded wide large colour="green">
                <template #text>
                  <b>Join Room</b>
                </template>
              </btn>

              <btn @click="handleCancelJoinLobby" class="cancel" rounded wide large colour="ghost">
                <template #text>
                  <b>Cancel</b>
                </template>
              </btn>
            </div>
          </div>

          <div v-else-if="!ifAuthenticated && guestData && guestData.username && !hideSuggestedGuestLogin" class="guest existing-guest">
            <p><b>Do you wish to reuse existing guest account?</b></p>
            <div class="guest-card">
              <p>{{guestData.username}}</p>
            </div>

            <div class="buttons">
              <btn @click="hideGuestSuggestionHandler" rounded wide large colour="ghost">
                <template #text>
                  <b>Switch User</b>
                </template>
              </btn>
              <btn @click="() => joinRoomAsExistingGuest()" rounded wide large colour="green">
                <template #text>
                  <b>Join Room</b>
                </template>
              </btn>
            </div>
          </div>
          <div v-else-if="!ifAuthenticated" class="guest">
            <inputfield
              large
              rounded
              placeholder="Type in your name"
              :customClasses="['bg-light-black', 'text-white', 'text-bold']"
              v-model="guestName"
              @keypress.enter="joinRoom"
            >
            </inputfield>

            <btn @click="joinRoom" :disabled="!guestName" rounded wide large colour="green" style="margin-top: 1rem;">
              <template #text>
                <b>Join Room</b>
              </template>
            </btn>

            <div class="guest__terms">
              <small>
                By continuing I agree to SPACEIN
                <span @click.stop><a target="_blank" href="https://spacein.co.uk/terms-and-conditions" class="text-white">
                  <b>Terms & Conditions</b>
                </a></span>
                and
                <span @click.stop><a target="_blank" href="https://spacein.co.uk/privacy-policy" class="text-white">
                  <b>Privacy Policy.</b>
                </a>
                </span>
              </small>
            </div>

            <p class="already-member">Already a member? <span class="login" @click="goToLogin"><b>Login</b></span></p>
          </div>
          <div v-else class="current-user">
            <div class="user-details">
              <user-icon :currentUser="true" userStatus="offline" />
              <div class="info">
                <p class="full-name">{{fullName}}</p>
                <p class="email">{{email}}</p>
              </div>
            </div>
            <div class="buttons">
              <btn class="logout" @click="onLogout" rounded wide large colour="ghost">
                <template #text>
                  <b>Logout</b>
                </template>
              </btn>
              <btn @click="joinRoom" rounded wide large colour="green">
                <template #text>
                  <b>Join Room</b>
                </template>
              </btn>
            </div>
          </div>
        </div>
        <div v-else class="left-room" :class="{authenticated: ifAuthenticated}">
          <div v-if="ifAuthenticated" class="user-details">
            <user-icon :currentUser="true" userStatus="offline" />
            <p class="full-name">{{fullName}}</p>
            <p class="email">{{email}}</p>
          </div>
          <p>You have left the Room.</p>

          <div class="buttons">
            <btn v-if="!isKicked" @click="reJoinRoom" rounded wide large colour="mauve">
              <template #text>
                <b>Rejoin</b>
              </template>
            </btn>

            <btn v-if="ifAuthenticated && !isExternalGuest" @click="goToHome" rounded wide large colour="yellow">
              <template #text>
                <b>Go to my Spaces</b>
              </template>
            </btn>
            <btn v-if="ifAuthenticated" @click="onLogout" class="logout" rounded wide large colour="ghost">
              <template #text>
                <b>Logout</b>
              </template>
            </btn>
          </div>
        </div>
        <div v-if="(!ifJoining && isExternalGuest) || !validInvite || isRejected || isKicked" class="footer">
          <hr>
          <div class="footer__content">
            <p v-if="isExternalGuest">Want to try SPACEIN for yourself? <br> Create your own community.</p>
            <btn v-if="isExternalGuest" @click="() => registerAsGuestHandler()" rounded wide large colour="yellow">
              <template #text>
                <b>Sign up to SPACEIN</b>
              </template>
            </btn>
            <btn v-if="ifAuthenticated && !isExternalGuest" @click="goToHome" rounded wide large colour="yellow">
              <template #text>
                <b>Go to my Spaces</b>
              </template>
            </btn>

            <btn v-if="ifAuthenticated && !isLeaving" @click="onLogout" class="logout" rounded wide large colour="ghost">
              <template #text>
                <b>Logout</b>
              </template>
            </btn>
          </div>
        </div>
      </div>
    </div>
    <LoadingIcon v-else expand />
  </div>
</template>

<script>
import LoadingIcon from '@/components/global/loading/LoadingIcon.vue';
import Inputfield from '@/framework/inputfield/Inputfield.vue';
import UserIcon from '@/components/UserIcon.vue'
import JitsiPreview from '@/components/conferencing/JitsiPreview.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'RoomLobby',
  components: { LoadingIcon, UserIcon, Inputfield, JitsiPreview },
  data: () => ({
    loadingInviteData: true,
    guestName: '',
    enteredPassword: '', //password value,
    showPassword: false,
    passwordOnLoad: null, //if there is a password in url params on load
  }),
  computed: {
    ...mapGetters(['showRoomLobby', 'isOpenSpace', 'getCurrentAreaId']),
    ...mapGetters('api/auth', {
      ifAuthenticated: 'authenticated', //if registered user
    }),
    ...mapGetters('routing', ['getParamValue']),
    ...mapGetters('current_user', ['fullName', 'email', 'isExternalGuest']),
    ...mapGetters('guest/room', ['roomName', 'isLeaving', 'isKicked', 'isInvalid', 'invalidReason', 'isPasswordRequired', 'isWaiting', 'isRejected', 'roomId', 'confType']),
    ...mapGetters('guest', ['isEnteringAfterAuth', 'isRejoiningAfterAuth', 'hideSuggestedGuestLogin']),
    ...mapGetters({
      guestData: 'guest/guestStorageData' //guest data from local storage
    }),
    ...mapGetters('viewport', ['isTabletOrDown']),
    validInvite() {
      return !!this.roomId && !this.isInvalid
    },
    ifJoining() { //if user is trying to join before clicking join room
      return !this.isLeaving && this.validInvite && !this.isRejected
    },
    invalidMessage() {
      let message = "This invite is not valid. Please request a new one."

      switch (this.invalidReason) {
        case "no_token_provided":
          break
        case "not_found":
          break
        case "space_not_found":
          message = "This Room can't be found. Please try again."
          break
      }

      return message
    },
    ifJitsiRoom() {
      return this.confType === 'spacein' && !this.isTabletOrDown ? true : false //don't show jitsi setup if not required for room when entering
    }
  },
  watch: {
    isEnteringAfterAuth(newVal) {
      //join if switches to true
      if(newVal) this.checkIfEnteringAfterAuth()
    },
    isRejoiningAfterAuth(newVal) {
      //join if switches to true
      if(newVal) this.checkIfRejoiningAfterAuth()
    },
    async showRoomLobby(newVal, oldVal) {
      if(newVal && !oldVal) {
        this.loadingInviteData = true
        await this.getRoomIdFromURL()
        await this.getInviteData()
        await this.handleCheckForGuestInStorage()

        this.checkIfEnteringAfterAuth()
        this.checkIfRejoiningAfterAuth()
        this.checkIfJoiningFromSpace()
      }
    }
  },
  methods: {
    ...mapMutations(['guest/room/ROOM_ID', 'guest/HIDE_SUGGESTED_GUEST_LOGIN']),
    ...mapActions(['handleJoinRoom']),
    ...mapActions('routing', ['goToHome', 'goToLogin', 'getQueryValue']),
    ...mapActions('api/auth', ['logout']),
    ...mapActions('guest/room', ['handleAccessLobby', 'handleEnterLobby', 'handleCancelJoinLobby', 'handleEnterLobbyWithPassword', 'handleEnterRoomWithPassword', 'refreshLobby', 'joinRoomAsExistingGuest']),
    ...mapActions('guest', ['handleCheckForGuestInStorage', 'registerAsGuestHandler']),
    async getRoomIdFromURL() {
      let roomId = this.getParamValue('roomId')
      this['guest/room/ROOM_ID'](roomId)
      let password = await this.getQueryValue('p')
      if(password) this.passwordOnLoad = password
      return
    },
    async getInviteData() {
      await this.handleAccessLobby(this.roomId)
      this.loadingInviteData = false
      return
    },
    handleLogoClick() {
      if(this.ifAuthenticated) this.goToHome()
      else this.registerAsGuestHandler()
    },
    onLogout() {
      // Log out the user, requesting that we then go to the login page and redirect back to this URL
      // so we can keep the same invite token
      const currentUrl = window.location.pathname
      this.logout({
        type: 'loginThenRedirect',
        url: currentUrl
      })
    },
    joinRoom() {
      let name = this.guestName || this.fullName
      if(!name || !this.roomId) return console.log('no name or no room id')
      if(this.passwordOnLoad) { //password from url params
        this.handleEnterRoomWithPassword([this.passwordOnLoad,name])
        return
      }
      //password
      if(this.isWaiting && this.isPasswordRequired) {
        if(!this.enteredPassword) console.log('password invalid')
        else this.handleEnterLobbyWithPassword([this.enteredPassword])
      } else this.handleEnterLobby(name) //no password
    },
    async reJoinRoom() {
      await this.joinRoomAsExistingGuest('rejoin')
      this.refreshLobby() //restart guest flow if fails
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    checkIfEnteringAfterAuth() {
      if(this.isEnteringAfterAuth && this.roomId) {
        this.joinRoom()
      }
    },
    checkIfRejoiningAfterAuth() {
      if(this.isRejoiningAfterAuth && this.roomId) {
        this.handleJoinRoom(this.roomId)
      }
    },
    checkIfJoiningFromSpace() {
      // if joining from a space (already on the site), skip first (user / name) step of lobby
      if(this.showRoomLobby && this.getCurrentAreaId && this.fullName) {
        this.joinRoom();
      }
    },
    hideGuestSuggestionHandler() {
      this['guest/HIDE_SUGGESTED_GUEST_LOGIN'](true)
    }
  },
  async mounted() {
    if(this.showRoomLobby) {
      await this.getRoomIdFromURL()
      await this.getInviteData()
      await this.handleCheckForGuestInStorage()
    }
    this.checkIfJoiningFromSpace()
    //dont do these if entering from space overview as external guest (open space) (as will enter lobby)
    if(this.isOpenSpace && this.getCurrentAreaId) return
    this.checkIfEnteringAfterAuth()
    this.checkIfRejoiningAfterAuth()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/pages/room_lobby.scss';
</style>
