<template>
  <div class="jitsi-iframe " id="jitsi" >
    <LoadingIcon v-if="loading" expand />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import LoadingIcon from '@/components/global/loading/LoadingIcon.vue';

export default {
  name: 'JitsiPreview',
  components: { LoadingIcon },
  data() {
    return {
      jaasToken: "",
      loading: true,
      muteAudio: false, //pulled from url
      muteVideo: false, //pulled from url
      videoMuted: true, //change listener
      audioMuted: true, //change listener
    };
  },
  computed: {
    ...mapGetters({
      usersData: 'current_user/data',
    }),
    usersName() {
      let name = 'Setup';
      if (this.usersData) {
        name = `${this.usersData.firstName} ${this.usersData.lastName} ${this.usersData.pronouns ? `(${this.usersData.pronouns})` : ''}`;
      }
      return name;
    },
    id() {
      return uuid()
    },
  },
  methods: {
    ...mapActions('routing', ['addQuery', 'getQueryValue']),
    openJitsiIframe() {
      const options = {
        roomName: `vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8/${this.id}`,
        parentNode: document.querySelector('#jitsi'),
        jwt: this.jaasToken,
        configOverwrite: {
          startWithAudioMuted: this.muteAudio,
          startWithVideoMuted: this.muteVideo,
          constraints: {
            video: {
              aspectRatio: 16 / 9,
              // height: {
              //   ideal: 720,
              //   max: 720,
              //   min: 240
              // }
            }
          },
          enableLayerSuspension: true,
          prejoinPageEnabled: false,
          disableThirdPartyRequests: false,
        },
        interfaceConfigOverwrite: {
          ENABLE_FEEDBACK_ANIMATION: true,
          MAXIMUM_ZOOMING_COEFFICIENT: 1,
          APP_NAME: 'SPACEIN',
          DEFAULT_BACKGROUND: '#000000',
          DEFAULT_LOCAL_DISPLAY_NAME: 'me',
          DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow participant',
          DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
          DISABLE_FOCUS_INDICATOR: true,
          DISABLE_PRESENCE_STATUS: true,
          DISABLE_VIDEO_BACKGROUND: true,
          HIDE_INVITE_MORE_HEADER: true,
          LOCAL_THUMBNAIL_RATIO: 16 / 9,
          SHOW_CHROME_EXTENSION_BANNER: false,

          TOOLBAR_BUTTONS: [
            'microphone',
            'camera'
          ],
        }
      };
      const api = new window.JitsiMeetExternalAPI("8x8.vc", options);
      api.addListener('videoConferenceJoined', () =>
        {
          this.loading = false
          api.executeCommand('subject', 'Setup')
          api.executeCommand('toggleFilmStrip')
        }
      )
      api.addListener('audioMuteStatusChanged', (e) => {
        console.log('HI', e.muted)
        this.audioMuted = e.muted
        this.updateUrlParams()
      })
      api.addListener('videoMuteStatusChanged', (e) => {
        console.log('HI', e.muted)
        this.videoMuted = e.muted
        this.updateUrlParams()
      })
    },
    getJaaStoken(){
      let jaasAuthDomain = process.env.VUE_APP_JAAS_AUTH_DOMAIN
      axios.post(jaasAuthDomain,
        {
          "id": this.id,
          "name": this.usersName,
          // "email": this.usersEmail,
          "appid": "vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8",
          "kid": "vpaas-magic-cookie-605c24f7880c40ea9f86c029ef859dc8/9ba40b",
          "moderator": true
        })
      .then(response => {
        this.jaasToken = response.data

        this.openJitsiIframe();
      })
    },
    async getConfigUrlParams() {
      let muteAudio = await this.getQueryValue('audio-muted')
      muteAudio === 'true' ? this.muteAudio = true : this.muteAudio = false
      let muteVideo = await this.getQueryValue('video-muted')
      muteVideo === 'true' ? this.muteVideo = true : this.muteVideo = false
    },
    updateUrlParams() {
      this.addQuery({
        'video-muted': this.videoMuted,
        'audio-muted': this.audioMuted
      })
    },
  },
  async mounted() {
    await this.getConfigUrlParams()
    this.getJaaStoken();
    //allow for current users room to update and stuff when space switching
  },
};
</script>

<style lang="scss" scoped>
  .jitsi-iframe {
    position: relative;
    align-self: center;
    height: 40vh;
    width: calc(100% - 4px);
    box-shadow: 1px 1px 15px 0px rgba(255,255,255,0.45);
    border-radius: 1rem;
    overflow: hidden;

    .loading-icon {
      opacity: 0.8;
    }
  }
</style>
